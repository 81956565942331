import React, { useEffect } from 'react';

import { VSCodeButton, VSCodeTextField } from "@vscode/webview-ui-toolkit/react";

import { validateLicense, activateLicense } from '@lemonsqueezy/lemonsqueezy.js';


// eslint-disable-next-line react/prop-types
export default function LicenseKey({ licenseValid, setLicenseValid, licenseKey, setLicenseKey }) {

  async function checkLicenseValidity() {
    if (!licenseKey) return;

    const validationResult = await validateLicense(licenseKey);
    if (validationResult.statusCode === 200) {
      setLicenseValid(true);
      console.log("License is valid");
      console.log(validationResult.data);
    } else {
      setLicenseValid(false);
      console.log("License is invalid", validationResult.error);
    }
  }

  async function handleSetLicenseKey() {
    const validationResult = await validateLicense(licenseKey);
    if (validationResult.statusCode === 200) {
      setLicenseValid(true);
      console.log("License is valid");
      console.log(validationResult.data);
    } else {
      console.log("License is invalid", validationResult.error);
      const activationResult = await activateLicense(licenseKey, window.machineId);
      if (activationResult.statusCode === 200) {
        setLicenseValid(true);
        console.log("License is valid");
        console.log(activationResult.data);
      } else {
        setLicenseValid(false);
        console.log("License is invalid", activationResult.error);
      }
    }
  }

  useEffect(() => {
    if (licenseKey != '')
      checkLicenseValidity();
  }, [licenseKey]);

  return (
    <div className="flex gap-2 px-2 items-center">
      <VSCodeTextField
        value={licenseKey}
        onInput={(e) => setLicenseKey(e.target.value)}
        placeholder="Enter your license key here.."
      ></VSCodeTextField>
      {!licenseValid ? (<VSCodeButton className='h-6 px-4' onClick={handleSetLicenseKey}>{'Activate'}</VSCodeButton>) : ''}
      {!licenseValid ? (<a href="https://lemonsqueezy.com" target="_blank" rel="noreferrer" className="text-blue-500">Get a license</a>): ''}
    </div>
  );

}