import React, { useState, useEffect, useRef } from "react";
import {createParser } from 'eventsource-parser'
// Import necessary components from @vscode/webview-ui-toolkit
import { VSCodeButton, VSCodeTextArea } from "@vscode/webview-ui-toolkit/react";
import { vscode } from "./utilities/vscode";
import "./App.css";
import ChatBubble from "./components/ChatBubble";
import LicenseKey from "./components/LicenseKey";
import Attachments from "./components/Attachments";
import Dropdown from "./components/Dropdown";
import ChatControls from "./components/ChatControlls";
import ApiKey from "./components/ApiKey";




function App() {
  function getInitialState() {
    const localStorageState = vscode.getState();
    return {
      apiKey: localStorageState?.apiKey || window?.initialState?.apiKey || '',
      input: localStorageState?.input || window?.initialState?.input || '',
      model: localStorageState?.model || window?.initialState?.model || 'gpt-4-turbo-preview',
      messages: localStorageState?.messages || window?.initialState?.messages || [],
      licenseKey: localStorageState?.licenseKey || window?.initialState?.licenseKey || '',
    };
  }

  // Initialize states directly using the function to avoid redundancy
  const initialState = getInitialState();
  const [input, setInput] = useState(initialState.input);
  const [apiKey, setApiKey] = useState(initialState.apiKey);
  const [model, setModel] = useState(initialState.model);
  const [licenseValid, setLicenseValid] = useState(false);
  const [licenseKey, setLicenseKey] = useState(initialState.licenseKey);
  /*const [systemMessages, setSystemMessages] = useState([
    { role: "system", content: "You are ChatGPT a helpful assistant." },
  ]);*/
  const systemMessages = [{
    role: "system",
    content: `You are a helpful AI assistant. Today is ${new Date().toLocaleDateString()}, local time is ${new Date().toLocaleTimeString()}.`
  }]

  const [messages, setMessages] = useState(initialState.messages);
  const [attachments, setAttachments] = useState({});

  // Use useEffect to sync state changes to localStorage
  useEffect(() => {
    vscode.setState({ apiKey, model, messages, licenseKey });
  }, [apiKey, model, messages, licenseKey]);



  useEffect(() => {
    window.addEventListener('message', async event => {
      const message = event.data; // The JSON data our extension sent
      switch (message.command) {
        case 'loadFile': {
          const { webviewUri, relativePath } = message;
          setAttachments(prevAttachments => ({
            ...prevAttachments,
            [relativePath]: webviewUri,
          }));
          break;
        }
      }
      });
  }, []);

  function onParse(messagesWithHistory, event) {
    if (event.type === 'event') {
      if (event.data === '[DONE]') {
        console.log('Stream completed');
        return;
      }
      const data = JSON.parse(event.data)
      if (data.choices[0].finish_reason === 'stop') {
        return;
      }
      const content = data.choices[0].delta.content;
      const updatedMessages = [...messagesWithHistory];
      const lastMessageIndex = updatedMessages.length - 1;
      if (updatedMessages[lastMessageIndex]) {
        updatedMessages[lastMessageIndex].content += content;
      }
      setMessages(updatedMessages);

    } else {
      console.error('Received unknown event type: %s', event.type)
    }
  }

  async function createAttachments() {
    const attachmentEntries = Object.entries(attachments);
  
    const filesPromises = attachmentEntries.map(async ([relativePath, uri]) => {
      const content = await fetch(uri).then(response => response.text());
      return `<FILE_ATTACHMENT><FILE_PATH>${relativePath}</FILE_PATH>\n<FILE_CONTENT>${content}</FILE_CONTENT></FILE_ATTACHMENT>\n`;
    });
  
    const completedFiles = await Promise.all(filesPromises);
    const combinedFilesContent = completedFiles.join('');
    return([{ role: "user", content: combinedFilesContent }]);
  }
  
  
  

  // Function to handle sending a message
  async function handleSendMessage() {
    if (input.trim() === "") return;
    const updatedMessages = [...messages, { role: "user", content: input }, { role: "assistant", content: "" }];
    setMessages(updatedMessages);

    const attachmentMessages = await createAttachments();
    // Call the function to interact with OpenAI's API
    await sendMessageToOpenAI(systemMessages, attachmentMessages, updatedMessages);
    setInput(""); // Clear the input after sending
  }

  // Function to interact with OpenAI's API
  async function sendMessageToOpenAI(initMessages, attachmentMessages, messagesWithHistory) {
    if (!apiKey) {
      vscode.postAlert("Please enter your OpenAI API key.");
      return;
    }

    try {
      const url = 'https://api.openai.com/v1/chat/completions';

      const headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Authorization', `Bearer ${apiKey}`);

      console.log("Sending message to OpenAI...", [...initMessages, ...attachmentMessages, ...messagesWithHistory]);

  
      const requestBody = {
        messages: [...initMessages, ...attachmentMessages, ...messagesWithHistory],
        model: model,
        stream: true,
        temperature: 0.7,
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        const errorData = await response.json();
        // Handle the specific error when context length is exceeded
        if (response.status === 400) {
          vscode.postAlert(errorData.error.message);
          setMessages(messagesWithHistory.slice(0, -1));
          return;
        }
        throw new Error('Failed to communicate with OpenAI API');
      }

      const reader = response.body.getReader();
      let decoder = new TextDecoder();


      const parser = createParser(event => onParse(messagesWithHistory, event));

      const streamData = async (reader) => {
        let { done, value } = await reader.read();
        if (done) {
          console.log('Stream ended');
          return;
        }

        parser.feed(decoder.decode(value));
        streamData(reader); 
      };

      streamData(reader);

    } catch (error) {
      console.error("Failed to send message to OpenAI:", error);
    }
  }

  const scrollRef = useRef(null);
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages])

  return (
    <main className="flex flex-col h-max w-full">
      <div className="p-2 flex items-center">
        <ApiKey apiKey={apiKey} setApiKey={setApiKey} />
        <Dropdown   
          label="Select Model"
          options={[
            { label: "GPT-4 Turbo", value: "gpt-4-turbo-preview" },
            { label: "GPT-4", value: "gpt-4-32k	" },
            { label: "GPT-3.5", value: "gpt-3.5-turbo" }
          ]}
          value={model}
          id="model" onChange={(e) => setModel(e.target.value)} />
        <LicenseKey licenseValid={licenseValid} setLicenseValid={setLicenseValid} licenseKey={licenseKey} setLicenseKey={setLicenseKey} />
      </div>
      <div className="w-full">
        {[...systemMessages, ...messages].map((message, index) => (
          <ChatBubble key={index} role={message.role} content={message.content} />
        ))}
        <div className="h-24" ref={scrollRef}></div>
      </div>
      <div className="flex flex-col gap-2 p-4 fixed bottom-0 left-0 right-0">
        <ChatControls messages={messages} setMessages={setMessages} />
        <Attachments attachments={attachments} setAttachments={setAttachments} />
        <div className="flex gap-2">
          <VSCodeTextArea
            value={input}
            onInput={(e) => setInput(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault(); // Prevent the default action to not insert a new line
                handleSendMessage();
              }
            }}
            placeholder="Type your message here..."
            className="flex-1"
            autoAdjustHeight // Automatically adjust the height based on the content
          ></VSCodeTextArea>
          <VSCodeButton onClick={handleSendMessage}>Send</VSCodeButton>
        </div>
      </div>

    </main>
  );
}

export default App;
