import React from 'react';
import PropTypes from 'prop-types';

import { VSCodeDropdown, VSCodeOption } from "@vscode/webview-ui-toolkit/react";

const Dropdown = ({ label, options, id, value, onChange }) => {
  return (
    <div className="flex items-center px-2">
      {label && <label className='px-1 whitespace-nowrap' htmlFor={id}>{label}</label>}
      <VSCodeDropdown id={id} value={value} onChange={onChange}>
        {options.map((option, index) => (
          <VSCodeOption key={index} value={option.value}>{option.label}</VSCodeOption>
        ))}
      </VSCodeDropdown>
    </div>
  );
};

Dropdown.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.string, // Define the value prop
  onChange: PropTypes.func,
};

export default Dropdown;
