import React from "react";
import { VSCodeButton } from "@vscode/webview-ui-toolkit/react";
import PropTypes from "prop-types";

export default function ChatControls({ messages, setMessages }) {
  const onClearChat = () => {
    setMessages([]);
  };
  
  if (messages.length < 2) {
    // if there is only the system message, don't show the clear chat button
    return null;
  }

  return (
    <div className="flex justify-center">
      <VSCodeButton onClick={onClearChat}>Clear Chat</VSCodeButton>
    </div>
  );
}

ChatControls.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      role: PropTypes.string.isRequired,
      content: PropTypes.string.isRequired,
    })
  ).isRequired,
  setMessages: PropTypes.func.isRequired
};