import React from "react";
import { VSCodeTag, VSCodeButton } from "@vscode/webview-ui-toolkit/react";
import PropTypes from "prop-types";

export default function Attachments({ attachments, setAttachments }) {

  const onRemoveAttachment = (relativePath) => {
    setAttachments((prevAttachments) => {
      const updatedAttachments = { ...prevAttachments };
      delete updatedAttachments[relativePath];
      return updatedAttachments;
    });
  };


  return (
    <div className="flex flex-wrap">
      {Object.entries(attachments).map(([relativePath, uri]) => {
        return (
          <div key={uri} className="m-2">
            <VSCodeTag>
              <div className="flex items-center">
                <span className="mr-2">{relativePath}</span>
                <VSCodeButton
                  appearance="icon"
                  onClick={() => onRemoveAttachment(relativePath)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 16 16"
                    width="16"
                    height="16"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.72 3.72a.75.75 0 011.06 0L8 6.94l3.22-3.22a.75.75 0 111.06 1.06L9.06 8l3.22 3.22a.75.75 0 11-1.06 1.06L8 9.06l-3.22 3.22a.75.75 0 01-1.06-1.06L6.94 8 3.72 4.78a.75.75 0 010-1.06z"
                    />
                  </svg>
                </VSCodeButton>
              </div>
            </VSCodeTag>
          </div>
        );
      })}
    </div>
  );
}

Attachments.propTypes = {
  attachments: PropTypes.object.isRequired,
  setAttachments: PropTypes.func.isRequired,
};
