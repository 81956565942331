import React from "react";
import PropTypes from "prop-types";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';


const ChatBubble = ({ role, content }) => {
  const markdownComponents = {
    p: ({...props}) => <p className="my-2" {...props} />,
    a: ({...props}) => <a className="text-blue-500 hover:underline" {...props} />,
    h1: ({...props}) => <h1 className="text-2xl font-bold my-2" {...props} />,
    h2: ({...props}) => <h2 className="text-xl font-bold my-2" {...props} />,
    h3: ({...props}) => <h3 className="text-lg font-bold my-2" {...props} />,
    ul: ({...props}) => <ul className="list-disc pl-5 my-2" {...props} />,
    ol: ({...props}) => <ol className="list-decimal pl-5 my-2" {...props} />,
    li: ({...props}) => <li className="ml-2" {...props} />,
    table: ({...props }) => <table className="min-w-full leading-normal mt-4 mb-4 border-collapse border border-gray-700" {...props} />,
    thead: ({...props }) => <thead className="text-left text-gray-200 bg-gray-800" {...props} />,
    tbody: ({...props }) => <tbody className="text-gray-300" {...props} />,
    tr: ({ ...props }) => <tr className="hover:bg-gray-700" {...props} />,
    th: ({ ...props }) => <th className="px-5 py-3 border-b-2 border-gray-700 text-base" {...props} />,
    td: ({ ...props }) => <td className="px-5 py-2 border-b border-gray-700 text-base" {...props} />,
    code: ({...props}) => <code className="p-1 rounded" {...props} />,
  };

  const CodeBlock = ({ language, part }) => {
    return (
      <SyntaxHighlighter language={language} style={vscDarkPlus}>
        {part}
      </SyntaxHighlighter>
    );
  };

  CodeBlock.propTypes = {
    language: PropTypes.string.isRequired,
    part: PropTypes.string.isRequired,
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy to clipboard', err);
    }
  };

  const renderContent = () => {
    const parts = content.split(/(```)/);
    let inCodeBlock = false;
    let isFirstPart = true;
    let language = null;

    return parts.flatMap((part, index) => {
      if (part === '```') {
        inCodeBlock = !inCodeBlock;
        if (!inCodeBlock) {
          // Reset the language to null when exiting a code block
          language = null;
        }
        return []; // Don't directly render the backticks
      }

      if (inCodeBlock) {
        if (language === null) {
          // eslint-disable-next-line react/prop-types
          const firstNewLineIndex = part.indexOf('\n');
          if (firstNewLineIndex !== -1) {
            // eslint-disable-next-line react/prop-types
            language = part.substring(0, firstNewLineIndex).trim();
            // eslint-disable-next-line react/prop-types
            part = part.substring(firstNewLineIndex + 1); // Remove the language line from the code content
          } else {
            // If there's no newline, the whole part is considered the language
            language = part;
            return []; // Skip rendering until the actual code part is reached
          }
        }
        return (
          <div className="relative">
            <button
              className="absolute right-2 top-2 text-xs text-gray-200 bg-gray-700 hover:bg-gray-600 p-1 rounded"
              onClick={() => copyToClipboard(part)}
            >
              Copy
            </button>
            <CodeBlock key={index} language={language} part={part} />
        </div>
        );
      } else {
        const renderPart = isFirstPart && part === "" ? "..." : <ReactMarkdown remarkPlugins={[remarkGfm]} components={markdownComponents}>{part}</ReactMarkdown>;
        isFirstPart = false;
        return renderPart;
      }
    });
  };

  return (
    <div className={`p-2 m-5 rounded flex-1 ${role === "user" ? "bg-blue-700" : role === "system" ? "bg-slate-600" : "bg-neutral-900"}`}>
      {renderContent()}
    </div>
  );
};

ChatBubble.propTypes = {
  role: PropTypes.oneOf(["user", "system", "assistant"]).isRequired,
  content: PropTypes.string.isRequired,
};

export default ChatBubble;