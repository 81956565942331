
import React from "react";
import PropTypes from "prop-types";
import { VSCodeTextField } from "@vscode/webview-ui-toolkit/react";


export default function ApiKey({ apiKey, setApiKey }) {
  return (
    <div className="flex items-center">
      <label className='px-2 whitespace-nowrap' htmlFor='apiKey'>OpenAI API Key</label>
      <VSCodeTextField
        id="apiKey"
        value={apiKey}
        onInput={(e) => setApiKey(e.target.value)}
        placeholder="Enter your OpenAI API Key here..."
        type="password"
        className="w-full" // Ensure the input field also fills the available space
      ></VSCodeTextField>
    </div>
  );
}

ApiKey.propTypes = {
    apiKey: PropTypes.string.isRequired,
    setApiKey: PropTypes.func.isRequired
};

